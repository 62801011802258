<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择性别" style="width:100%">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="出生日期">
            <el-date-picker
              v-model="form.birthDay"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请选择出生年月日"
              auto-complete="off"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="身份证" prop="idNum">
            <el-input v-model="form.idNum" placeholder="请输入身份证号" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="民族" prop="nationality">
            <el-select v-model="form.nationality" placeholder="请选择名族" style="width:100%">
              <el-option v-for="item in nationalityList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="照片" prop="photo">
            <upload-img :limit="1" :upload-lists="imgsList" @uploadChange="uploadSuccess" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="工作站点" prop="workStationName">
            <el-input v-model="form.workStationName" placeholder="请输入工作站点" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      },
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      titleName: '',
      form: {},
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        idNum: [{ pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请检查身份证号格式' }]
      },
      imgsList: [],
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      nationalityList: [
        { value: '汉族', label: '汉族' },
        { value: '蒙古族', label: '蒙古族' },
        { value: '回族', label: '回族' },
        { value: '藏族', label: '藏族' },
        { value: '维吾尔族', label: '维吾尔族' },
        { value: '苗族', label: '苗族' },
        { value: '彝族', label: '彝族' },
        { value: '壮族', label: '壮族' },
        { value: '布依族', label: '布依族' },
        { value: '朝鲜族', label: '朝鲜族' },
        { value: '满族', label: '满族' },
        { value: '侗族', label: '侗族' },
        { value: '瑶族', label: '瑶族' },
        { value: '白族', label: '白族' },
        { value: '土家族', label: '土家族' },
        { value: '哈尼族', label: '哈尼族' },
        { value: '哈萨克族', label: '哈萨克族' },
        { value: '傣族', label: '傣族' },
        { value: '黎族', label: '黎族' },
        { value: '傈僳族', label: '傈僳族' },
        { value: '佤族', label: '佤族' },
        { value: '畲族', label: '畲族' },
        { value: '高山族', label: '高山族' },
        { value: '拉祜族', label: '拉祜族' },
        { value: '水族', label: '水族' },
        { value: '东乡族', label: '东乡族' },
        { value: '纳西族', label: '纳西族' },
        { value: '景颇族', label: '景颇族' },
        { value: '柯尔克孜族', label: '柯尔克孜族' },
        { value: '土族', label: '土族' },
        { value: '达翰尔族', label: '达翰尔族' },
        { value: '么佬族', label: '么佬族' },
        { value: '羌族', label: '羌族' },
        { value: '布朗族', label: '布朗族' },
        { value: '撒拉族', label: '撒拉族' },
        { value: '毛南族', label: '毛南族' },
        { value: '仡佬族', label: '仡佬族' },
        { value: '锡伯族', label: '锡伯族' },
        { value: '阿昌族', label: '阿昌族' },
        { value: '普米族', label: '普米族' },
        { value: '塔吉克族', label: '塔吉克族' },
        { value: '怒族', label: '怒族' },
        { value: '乌孜别克族', label: '乌孜别克族' },
        { value: '俄罗斯族', label: '俄罗斯族' },
        { value: '鄂温克族', label: '鄂温克族' },
        { value: '德昂族', label: '德昂族' },
        { value: '保安族', label: '保安族' },
        { value: '裕固族', label: '裕固族' },
        { value: '京族', label: '京族' },
        { value: '塔塔尔族', label: '塔塔尔族' },
        { value: '独龙族', label: '独龙族' },
        { value: '鄂伦春族', label: '鄂伦春族' },
        { value: '赫哲族', label: '赫哲族' },
        { value: '门巴族', label: '门巴族' },
        { value: '珞巴族', label: '珞巴族' },
        { value: '基诺族', label: '基诺族' }
      ]
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['downloadFile', 'addAgedCounselor', 'detailAgedCounselor']),
    async initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加人员信息'
      } else {
        this.titleName = '修改人员信息'
        this.itemData = JSON.parse(this.$route.query.item)
        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'detailAgedCounselor'
        await this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              address: data.address,
              name: data.name,
              sex: data.sex,
              birthDay: data.birthDay,
              idNum: data.idNum,
              nationality: data.nationality,
              photo: data.photo,
              workStationId: data.workStationId,
              workStationName: data.workStationName
            }
          }
        })
        if (this.form.photo) {
          this.getFileDetail(this.form.photo)
        }
      }
    },
    getFileDetail(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.imgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    submitEvent(formName) {
      if (!this.form.photo) {
        this.$XModal.message({
          message: '请上传照片',
          status: 'warning'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          const server = this.routeType == 'add' ? 'addAgedCounselor' : 'addAgedCounselor'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    uploadSuccess(data) {
      this.form.photo = data.join(',')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
